import { Button } from 'components/styled/button'
import * as React from 'react'
import styled from 'styled-components'

// ASSETS
import ArrowIcon from 'svgs/arrow_top_right.svg'
import { SCREEN } from 'styles/screens'
import { getTranslation } from '../../data/dictionary'
import { useEffect, useState } from 'react'

const Container = styled.div`
  display: none;
  .h-50px {
    height: 50px;
  }
  .pt-5px {
    padding-top: 5px;
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    >div {
      display: flex;
      align-items: center;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(7px);
    z-index: 50;
    > h6 {
      font-size: 1rem;
      margin: 0;
      font-weight: 400;
    }
    div {
      button {
        margin-left: 2px;
        padding: 8px 16px;
        > svg {
          margin-left: 8px;
          height: 10px;
        }
      }
    }
  }
`

const MobileCTA = ({ handleModal }) => {

  const [programs, setPrograms] = useState([]);

  useEffect(async () => {
    fetch(`https://backend.buildup.am/programs`).then(response => response.json()).then(response=>{
      setPrograms(response.filter(program => program.enabled))
    })
  },[]);

  return <Container>
    <h6>{getTranslation('Become one of us', 'Become one of us')}</h6>
    <div>
      <div className="position-relative h-50px pt-5px open-mobile-menu-on-hover">
        <Button onClick={() => {
          window.location.href = '/program'
        }}>
          Apply <ArrowIcon />
        </Button>
        <div className="position-absolute mobile-apply-menu" id="mobile-apply-menu">
          <ul>
            {programs.map(program => {
              return <li key={program.key}>
                <a href={`/program/${program.key}`}>
                  {program.data.title}
                </a>
              </li>
            })}
          </ul>
        </div>
      </div>

      <Button onClick={() => handleModal(true)} secondary>
        Join <ArrowIcon />
      </Button>
    </div>
  </Container>
}

export default MobileCTA
