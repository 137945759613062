import React, { useState } from 'react'

import styled from 'styled-components'
import { InputBase } from './input'
import { Button } from './button'
import ArrowIcon from '../../svgs/arrow_top_right.svg'
import { SCREEN } from 'styles/screens'
import axios from 'axios'
import { config } from '../../config'
import { getTranslation } from '../../data/dictionary'

const Container = styled.div`
  position: relative;
  max-width: calc(var(--spacing) * 100);
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    max-width: 100%;
  }
`

const StyledInput = styled(InputBase)`
  color: var(--primary-light);
  background-color: var(--primary);
  border: 1px var(--primary-light) solid;
  padding-right: calc(var(--spacing) * 44);
  width: 100%;
  &:focus {
    border-color: var(--primary-light);
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding-right: calc(var(--spacing) * 31);
  }
`

const Error = styled.div`
  color: red;
`

const Success = styled.div`
  color: green;
`

const StyledButton = styled(Button)`
  position: absolute;
  top: calc(var(--spacing));
  right: calc(var(--spacing));
  height: calc(var(--spacing) * 12);
  &:hover {
    background-color: var(--white);
    border-color: var(--white);
  }
  > svg {
    margin-left: 12px;
  }
`

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const SubscribeInput = () => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)

  const subscribe = async () => {
    if (!validateEmail(email)) {
      setError('Invalid email address')
      return
    }
    setError('')
    setSuccess(false)
    try {
      let response = (
        await axios.post(`${config.API_BASE_URL}/users/newsletter/subscribe`, {
          email,
        })
      ).data
      if (response.success) {
        setSuccess(true)
      } else {
        setError(response.error || 'Error')
      }
    } catch (e) {
      setError('Error')
    }
  }

  return (
    <Container>
      <StyledInput
        value={email}
        onChange={e => {
          setEmail(e.target.value)
        }}
        placeholder={getTranslation("newsletter_email","hello@buildup.com",false)}
      />
      <StyledButton onClick={subscribe}>
        Subscribe <ArrowIcon />
      </StyledButton>
      {error && <Error>{error}</Error>}
      {success && <Success>Success</Success>}
    </Container>
  )
}

export default SubscribeInput
