import React from 'react'
import { Row } from 'react-bootstrap'
import styled from 'styled-components'
import GameChangerImage from '../../images/gameChanger.png'
import ArrowIcon from 'svgs/arrow_top_right.svg'


const Main = styled.div`

    .overlay{
    
        display: flex;
        align-items: center;
        justify-content: center;
        }

    .white{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 20px;
        span{
            font-size: 20px;
            margin: 10px;
        }
        .details{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 40px;

            .rows{
                display: flex;
                flex-direction: column;
                margin-left: 10px;
                font-weight: 500;
            }

            img{
                width: 170px;
                height: 300px;
            }
        }
    }

    .yellow{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        row-gap: 10px;

        .name{
            width: 350px;
            background: transparent;
            decoration: underline;
            margin-left: 30px;
            border: none;
            border-bottom: 2px solid black;
            margin-top: 50px;
            margin-bottom: 20px;
        }

        .email{
            width: 350px;
            background: transparent;
            decoration: none;
            margin-left: 30px;
             border: none;
            border-bottom: 2px solid black;
        }

        .close{
            font-weight: "200";
            margin-left: "380px";
            margin-top: "-80px";
            decoration: none;
            background: transparent;
            border: none;
            font-family: 'ubuntu';
            font-size: 25px;
            cursor: pointer;
        }
    }

`

const Button = styled.button`
width: 350px;
margin-left: 30px;
margin-top: 20px;
    display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: var(--primary);
  padding: calc(var(--spacing) * 2.5) calc(var(--spacing) * 6);
  border: 3px solid white;
  border-radius: var(--border-radius);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.6s;
  &:hover {
    box-shadow: inset 0 -3.25em 0 0 transparent;
    background-color: transparent;
  }
  @media only screen and (max-width: 769px) {
    padding: calc(var(--spacing) * 3) calc(var(--spacing) * 4);
    font-size: 16px;
  }
`

const DownloadPopup = ({ children, show, setShow }) => {

    const close = () => {
        setShow(false)
    }

    const content = show && (
       
        <Main>
        <div className="overlay" style={{ position: 'fixed', width: '100%', height: '100%', zIndex: 999999, backgroundColor: "rgba(0, 0, 0, 0.5)", top: 0, left: 0 }}>


        <div style={{position: 'fixed', width: '80%', height: '90%', background: 'black', display: 'flex', margin: "10%", borderRadius: "30px" }}>

            <div style={{ width: '64%', height: '100%', background: 'white', borderRadius: "30px 0 0 30px" }} className='white'>
                <h4 style={{width: "800px", marginLeft: "40px", marginBottom:"50px", lineHeight: "1.3"}}>Download The Free Problem-Based Methology You Need To Achieve The Career Of Your Dreams.</h4>
                <div className='details'>
                    <img src={GameChangerImage} alt="download" />
                    <div className='rows'>
                    <span><ArrowIcon></ArrowIcon>  A better intergrated system</span>
                    <span><ArrowIcon></ArrowIcon>  Organaizing your whole data</span>
                   <span><ArrowIcon></ArrowIcon>  Implementing Cache or CDN plugins</span>
                   <span><ArrowIcon></ArrowIcon>  Getting Fast Servers</span>
                   </div>
                </div>
            </div>
            <div style={{ width: '50%', height: '100%', background: '#ECFB70', borderRadius: "0 30px 30px 0"  }} className='yellow'>
            <button onClick={() => {close()}} style={{fontWeight: "700", marginLeft:"380px", marginTop: "-80px"}} className='close'>X</button>
                <h4 style={{width: "370px", marginLeft: "30px", lineHeight: "1.3", marginTop: "30px"}}>Strategies covered in methology</h4>
                <span style={{fontWeight: "500", width: "350px", fontSize: "22px", marginLeft: "30px"}}>Get your PDF fast now. Enter Name and email below
                     and get yourself a free copy
                </span>
                <input type="text" placeholder="Your name" className='name'/>
                <input type="email" placeholder="Your email" className='email'/>
                <Button><span>Request PDF  <ArrowIcon> </ArrowIcon></span></Button>
            </div>
        </div>
        </div>
        </Main>
      )
    
      return content
}

export default DownloadPopup;