import * as React from 'react'
import styled from 'styled-components'

import SubscriptionCard, {
  HeadingsContainer,
  StyledH2,
} from '../subscription/subscriptionCard'
import { Input } from '../styled/input'
import { Button } from '../styled/button'

// ASSETS
import MailIcon from 'svgs/mail.svg'
import { Paragraph } from '../styled/typography'
import axios from 'axios'
import { config } from '../../config'
import { useState } from 'react'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > div {
    margin: 8px 0;
    flex: 1 1 80%;
  }

  > button {
    width: 100%;
    margin: calc(var(--spacing) * 3) 0;
  }
`

const StyledHeadingContainer = styled(HeadingsContainer)`
  display: flex;
`
const IconContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`
const ResetIcon = () => (
  <IconContainer>
    <MailIcon />
  </IconContainer>
)

const ResetPasswordVerification = ({ onClose, onFinish, onBack }) => {
  const [email, setEmail] = useState('')

  const sendResetEmail = async () => {
    try {
      let { success, error } = (
        await axios.post(`${config.API_BASE_URL}/users/sendResetEmail`, {
          email,
        })
      ).data
      if (success) {
        setSuccess(true)
      } else {
        alert(error)
      }
    } catch (e) {
      alert('An error has occurred. Please try again later')
    }
  }

  const [success, setSuccess] = React.useState(false)
  const onCloseHandler = !success ? onClose : undefined
  return (
    <SubscriptionCard
      titles={
        success
          ? []
          : [
              { inverted: true, content: 'RESET' },
              { inverted: false, content: 'PASSWORD' },
            ]
      }
      isLeft={false}
      onClose={onCloseHandler}
      mainIcon={success && ResetIcon}
      hasBack={!success && onBack}
      onBack={onBack}
    >
      <Container>
        {success ? (
          <>
            <StyledHeadingContainer>
              <StyledH2>EMAIL</StyledH2> &nbsp;&nbsp;
              <StyledH2 inverted>SEND</StyledH2>
            </StyledHeadingContainer>
            <Paragraph>
              We just sent you a reset link to the Email you mentioned.
            </Paragraph>
            <Button onClick={onFinish}>Thank You</Button>
          </>
        ) : (
          <>
            <Paragraph>
              Type in the Email that you want us to send you reset link.
            </Paragraph>
            <Input
              label="Email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              type="email"
              placeholder="rob@thebob.com"
            />
            <Button onClick={sendResetEmail}>Send Reset Link</Button>
          </>
        )}
      </Container>
    </SubscriptionCard>
  )
}

export default ResetPasswordVerification
