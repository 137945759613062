import styled from 'styled-components'

import { SCREEN } from 'styles/screens'

export const Card = styled.div`
  display: inline-flex;
  flex-direction: column;
  background: var(--white);
  box-shadow: 0 16px 60px rgba(88, 80, 187, 0.12);
  border-radius: var(--border-radius);
  padding: calc(var(--spacing) * 6);
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
`
